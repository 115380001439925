import { graphql, StaticQuery, withPrefix } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import * as B from "react-bootstrap";
import styled from "styled-components";
import Footer from "../components/Footer";
import { ImageRow } from "../components/ImageRow";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "./index.css";

const Page = () => (
	<StaticQuery
		query={graphql`
			query {
				# image1: file(relativePath: { eq: "IMG_20190302_163132.png" }) {
				image1: file(relativePath: { eq: "G17001--exploded.png" }) {
					childImageSharp {
						fluid(quality: 100, maxWidth: 1400) {
							...GatsbyImageSharpFluid_noBase64
						}
					}
				}
				kidsInCar: file(relativePath: { eq: "01-03-19-04442.jpg" }) {
					childImageSharp {
						fluid(quality: 100, maxWidth: 1400) {
							...GatsbyImageSharpFluid_noBase64
						}
					}
				}
				bild1: file(relativePath: { eq: "magicwboys.jpg" }) {
					childImageSharp {
						fluid(quality: 100, maxWidth: 1400) {
							...GatsbyImageSharpFluid_noBase64
						}
					}
				}
				bild2: file(relativePath: { eq: "mgb-productshot.png" }) {
					childImageSharp {
						fluid(quality: 100, maxWidth: 1400) {
							...GatsbyImageSharpFluid_noBase64
						}
					}
				}
				bild3: file(relativePath: { eq: "magicwb3.jpg" }) {
					childImageSharp {
						fluid(quality: 100, maxWidth: 1400) {
							...GatsbyImageSharpFluid_noBase64
						}
					}
				}
				bild4: file(relativePath: { eq: "mbafrika.jpg" }) {
					childImageSharp {
						fluid(quality: 100, maxWidth: 1400) {
							...GatsbyImageSharpFluid_noBase64
						}
					}
				}
				bild5: file(relativePath: { eq: "mbconst.jpg" }) {
					childImageSharp {
						fluid(quality: 100, maxWidth: 1400) {
							...GatsbyImageSharpFluid_noBase64
						}
					}
				}
				bild6: file(relativePath: { eq: "mbfarm.jpg" }) {
					childImageSharp {
						fluid(quality: 100, maxWidth: 1400) {
							...GatsbyImageSharpFluid_noBase64
						}
					}
				}
				bild7: file(relativePath: { eq: "mbgirls.jpg" }) {
					childImageSharp {
						fluid(quality: 100, maxWidth: 1400) {
							...GatsbyImageSharpFluid_noBase64
						}
					}
				}
				bild8: file(relativePath: { eq: "090920_Magnet_with_boy-africa.png" }) {
					childImageSharp {
						fluid(quality: 100, maxWidth: 1400) {
							...GatsbyImageSharpFluid_noBase64
						}
					}
				}
				bild9: file(relativePath: { eq: "090920_Kids_Room_FARM.png" }) {
					childImageSharp {
						fluid(quality: 100, maxWidth: 1400) {
							...GatsbyImageSharpFluid_noBase64
						}
					}
				}
				bild10: file(relativePath: { eq: "102020_boysworld-revised.png" }) {
					childImageSharp {
						fluid(quality: 100, maxWidth: 1400) {
							...GatsbyImageSharpFluid_noBase64
						}
					}
				}
				bild11: file(relativePath: { eq: "102020_africa-revised.png" }) {
					childImageSharp {
						fluid(quality: 100, maxWidth: 1400) {
							...GatsbyImageSharpFluid_noBase64
						}
					}
				}
				bild12: file(relativePath: { eq: "102020_construction-revised.png" }) {
					childImageSharp {
						fluid(quality: 100, maxWidth: 1400) {
							...GatsbyImageSharpFluid_noBase64
						}
					}
				}
				bild13: file(relativePath: { eq: "102020_farm-revised.png" }) {
					childImageSharp {
						fluid(quality: 100, maxWidth: 1400) {
							...GatsbyImageSharpFluid_noBase64
						}
					}
				}
				bild14: file(relativePath: { eq: "102020_girlsworld-revised.png" }) {
					childImageSharp {
						fluid(quality: 100, maxWidth: 1400) {
							...GatsbyImageSharpFluid_noBase64
						}
					}
				}
			}
		`}
		render={(data) => <Page1 data={data} />}
	/>
);

const Page1 = ({ data }: { data: any }) => (
	<Layout>
		<SEO title="Magnetische Bücher" />

		<B.Container>
			<B.Row style={{ maxWidth: "100%" }}>
				<B.Col style={{ textAlign: "center" }}>
					<h1
						style={{
							textAlign: "center",
							marginTop: "3em",
							marginBottom: "0",
							padding: "0",
						}}
					>
						Phantasie und Spielspaß
					</h1>
					<h1 style={{ textAlign: "center", paddingTop: "0.5em" }}>
						Lustiges Spielzeug für pfiffige Kinder
					</h1>
				</B.Col>
			</B.Row>

			<B.Row style={{ maxWidth: "100%" }}>
				<B.Col>
					<div>
						<Img
							fluid={data.bild1.childImageSharp.fluid}
							style={{
								maxWidth: "700px",
								margin: "1em auto",
							}}
						/>
					</div>
					<TextPadding>
						<ContentCenterWrapper>
							<p style={{ lineHeight: "28px" }}>
								In unserer magischen Welt gibt es Wahre Helden! Es stehen
								Zaubertränke, Schätze, Bösewichte und natürlich auch die Helden als
								Magnet-Figuren zur Verfügung.
							</p>
						</ContentCenterWrapper>
					</TextPadding>
					{/* Quantities */}
					<Quantities>
						<div style={{ flexBasis: "200px", flex: 1, marginBottom: "2em" }}>
							<Quantity>5</Quantity>
							<div style={{ fontSize: ".8em", paddingLeft: ".5em" }}>
								Themen/Designs
							</div>
						</div>
						<div style={{ flexBasis: "200px", flex: 1, marginBottom: "2em" }}>
							<Quantity>A3/A4</Quantity>
							<div style={{ fontSize: ".8em", paddingLeft: ".5em" }}>
								Buchgröße (offen/zu)
							</div>
						</div>
						<div style={{ flexBasis: "200px", flex: 1, marginBottom: "2em" }}>
							<Quantity>ab 4 J.</Quantity>
							<div style={{ fontSize: ".8em", paddingLeft: ".5em" }}>
								Altersgruppe
							</div>
						</div>
					</Quantities>
				</B.Col>
			</B.Row>
			<B.Row style={{ maxWidth: "100%" }}>
				<B.Col style={{ textAlign: "center" }}>
					<h1
						style={{
							textAlign: "center",
							marginTop: "3em",
							marginBottom: "0",
							padding: "0",
						}}
					>
						5 unterschiedliche Designs
						<br />
					</h1>
					<h1 style={{ textAlign: "center", paddingTop: "0.5em" }}>
						Je 2 Spielszenen + Viele passende Magnetfiguren
					</h1>
				</B.Col>
			</B.Row>
			<B.Row style={{ maxWidth: "100%" }}>
				<B.Col>
					<div>
						<Img
							fluid={data.bild3.childImageSharp.fluid}
							style={{
								maxWidth: "700px",
								margin: "1em auto",
							}}
						/>
						Magische Welt - Wahre Helden
					</div>
				</B.Col>
				<B.Col>
					<div>
						<Img
							fluid={data.bild4.childImageSharp.fluid}
							style={{
								maxWidth: "700px",
								margin: "1em auto",
							}}
						/>
						Tiere in Afrika
					</div>
				</B.Col>
				<B.Col>
					<div>
						<Img
							fluid={data.bild5.childImageSharp.fluid}
							style={{
								maxWidth: "700px",
								margin: "1em auto",
							}}
						/>
						Baustelle - Wir bauen ein Haus
					</div>
				</B.Col>
				<B.Col>
					<div>
						<Img
							fluid={data.bild6.childImageSharp.fluid}
							style={{
								maxWidth: "700px",
								margin: "1em auto",
							}}
						/>
						Ferien auf dem Bauernhof
					</div>
				</B.Col>
				<B.Col>
					<div>
						<Img
							fluid={data.bild7.childImageSharp.fluid}
							style={{
								maxWidth: "700px",
								margin: "1em auto",
							}}
						/>
						Magische Welt - Zauberwald
					</div>
				</B.Col>
			</B.Row>
			<B.Row style={{ maxWidth: "100%" }}>
				<B.Col style={{ textAlign: "center" }}>
					<h1
						style={{
							textAlign: "center",
							marginTop: "3em",
							marginBottom: "0",
							padding: "0",
						}}
					>
						Kühlschrankmagnete
					</h1>
					<h1 style={{ textAlign: "center", paddingTop: "0.5em" }}>
						Sie kochen, Ihr Kind spielt!
					</h1>
					<div>
						<Img
							fluid={data.bild8.childImageSharp.fluid}
							style={{
								maxWidth: "700px",
								margin: "1em auto",
							}}
						/>
					</div>
					<TextPadding>
						<ContentCenterWrapper>
							<p style={{ lineHeight: "28px", paddingBottom: ".7em" }}>
								Diese Kinder-Magnete haften stark an metallischen Oberflächen und
								können deswegen auch an magnetischen Kühlschränken verwendet werden{" "}
								<br /> Freie Bahn für Phantasie und Spielspaß!
							</p>
						</ContentCenterWrapper>
					</TextPadding>
				</B.Col>
			</B.Row>
			<B.Row style={{ maxWidth: "100%" }}>
				<B.Col style={{ textAlign: "center" }}>
					<h1
						style={{
							textAlign: "center",
							marginTop: "3em",
							marginBottom: "0",
							padding: "0",
						}}
					>
						Fördert Malen, Spielen und Kreativität
					</h1>
					<h1 style={{ textAlign: "center", paddingTop: "0.5em" }}>
						Für Kindertafeln bestens geeignet
					</h1>
					<div>
						<Img
							fluid={data.bild9.childImageSharp.fluid}
							style={{
								maxWidth: "700px",
								margin: "1em auto",
							}}
						/>
					</div>
				</B.Col>
			</B.Row>
			<B.Row style={{ maxWidth: "100%" }}>
				<B.Col>
					<div>
						<Img
							fluid={data.bild10.childImageSharp.fluid}
							style={{
								maxWidth: "700px",
								margin: "1em auto",
							}}
						/>
						<a
							href="https://www.amazon.de/dp/B08FC8RQPN"
							target="_blank"
							style={{ color: "blue" }}
						>
							"Magische Welt - Wahre Helden" kaufen...
						</a>
					</div>
				</B.Col>
				<B.Col>
					<div>
						<Img
							fluid={data.bild11.childImageSharp.fluid}
							style={{
								maxWidth: "700px",
								margin: "1em auto",
							}}
						/>
						<a
							href="https://www.amazon.de/dp/B085TQSW24"
							target="_blank"
							style={{ color: "blue" }}
						>
							"Tiere Afrikas" kaufen...
						</a>
					</div>
				</B.Col>
				<B.Col>
					<div>
						<Img
							fluid={data.bild12.childImageSharp.fluid}
							style={{
								maxWidth: "700px",
								margin: "1em auto",
							}}
						/>
						<a
							href="https://www.amazon.de/dp/B08FC7LDVT"
							target="_blank"
							style={{ color: "blue" }}
						>
							"Baustelle - Wir bauen ein Haus" kaufen...
						</a>
					</div>
				</B.Col>
				<B.Col>
					<div>
						<Img
							fluid={data.bild13.childImageSharp.fluid}
							style={{
								maxWidth: "700px",
								margin: "1em auto",
							}}
						/>
						<a
							href="https://www.amazon.de/dp/B08FC859M5"
							target="_blank"
							style={{ color: "blue" }}
						>
							"Bauernhof-Tiere" kaufen...
						</a>
					</div>
				</B.Col>
				<B.Col>
					<div>
						<Img
							fluid={data.bild14.childImageSharp.fluid}
							style={{
								maxWidth: "700px",
								margin: "1em auto",
							}}
						/>
						<a
							href="https://www.amazon.de/dp/B08FC7RHLR"
							target="_blank"
							style={{ color: "blue" }}
						>
							"Magische Welt - Zauberwald" kaufen...
						</a>
					</div>
				</B.Col>
			</B.Row>
			<B.Row style={{ maxWidth: "100%" }}>
				<B.Col style={{ textAlign: "center" }}>
					<TextPadding>
						<div>
							<h2>Galoxee Vorteile auf einen Blick</h2>
							<ul style={{ marginTop: 0, textAlign: "center" }}>
								<li>Beste Qualität und Sicherheit </li>
								<li>Starke Magnethaftung</li>
								<li>Passende magnetische Unterlage</li>
								<li>Praktische Aufbewahrungs-Tasche</li>
								<li>Schadstoffgeprüft </li>
								<li>Pädagogisch wertvolles Mitbringsel</li>
							</ul>
						</div>
					</TextPadding>
				</B.Col>
			</B.Row>
		</B.Container>
	</Layout>
);

const Quantities = styled.div`
	text-align: center;
	@media (min-width: 600px) {
		margin: 0 auto;
		width: 100%;
		max-width: 600px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
`;

const Quantity = styled.div`
	font-family: Roboto;
	font-size: 3.5em;
	font-weight: 300;
	color: var(--galoxeeOrange);
`;

const TextPadding = styled.div`
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	@media (min-width: 742px) {
		padding-left: 0;
		padding-right: 0;
	}
`;

/**
 * Cenetered divs have a co
 * Height: 500px (if >= 742), 700px (if >= 920)
 */
const RowWrapper = styled.div`
	width: 100%;
	@media (min-width: 742px) {
		min-height: 480px;
	}
	@media (min-width: 920px) {
		min-height: 700px;
	}
`;

const RowTop = styled.div`
	@media (min-width: 742px) {
		display: flex;
	}
`;

const RowMiddle = styled.div`
	@media (min-width: 742px) {
		display: flex;
		align-items: center;
	}
`;

const RowLeft = styled.div`
	@media (min-width: 742px) {
		width: 50%;
		order: 1;
	}
`;

const RowRight = styled.div`
	@media (min-width: 742px) {
		width: 50%;
		order: 2;
	}
`;

/**
 * 520px for text
 */
const ContentCenterWrapper = styled.div`
	width: 100%;
	margin: 0 auto;
	text-align: center;
	@media (min-width: 742px) {
		max-width: 520px;
	}
`;

const ContentLeftWiderWrapper = styled.div`
	width: 100%;
	margin: 0 auto;
	text-align: left;
	@media (min-width: 742px) {
		max-width: 690px;
	}
`;

/** Ensure padding around the content */
const ContentWrapper = styled.div`
	padding: 3em;
`;

const ContentLeft = styled.div`
	color: white;
	margin: 0 auto;
	@media (min-width: 742px) {
		width: 260px;
		margin: 0 2em 0 auto;
	}
	@media (min-width: 920px) {
		width: 360px;
		font-size: 110%;
	}
`;

const ContentRight = styled.div`
	color: white;
	margin: 0 auto;
	@media (min-width: 742px) {
		width: 260px;
		margin: 0 auto 0 2em;
	}
	@media (min-width: 920px) {
		width: 360px;
		font-size: 110%;
	}
`;

const ImageA = styled.div`
	background-image: url("${withPrefix("/images/26-02-19-03933.jpg")}");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 100%;
	@media (min-width: 742px) {
		max-width: 50em;
		height: 300px;
		padding: 0;
		background-size: cover;
		margin: 0 auto;
	}
`;

const ImageB = styled.div`
	background-image: url("${withPrefix("/images/26-02-19-03945.jpg")}");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	padding-top: 100%;
	@media (min-width: 742px) {
		height: 480px;
		padding: 0;
		background-size: contain;
	}
	@media (min-width: 920px) {
		height: 700px;
	}
`;

const ImageC = styled.div`
	background-image: url("${withPrefix("/images/022619_Kids_Room.png")}");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 100%;
	@media (min-width: 742px) {
		height: 480px;
		padding: 0;
		background-size: contain;
	}
`;

const Image1 = styled.div`
	background-image: url("https://lorempixel.com/400/400/technics/");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 100%;
	@media (min-width: 742px) {
		height: 480px;
		padding: 0;
		background-size: cover;
	}
	@media (min-width: 920px) {
		height: 700px;
	}
`;

const Image2 = styled.div`
	background-image: url("https://lorempixel.com/400/400/sports/");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 100%;
	@media (min-width: 742px) {
		height: 480px;
		padding: 0;
		background-size: cover;
	}
	@media (min-width: 920px) {
		height: 700px;
	}
`;

const Image3 = styled.div`
	background-image: url("https://lorempixel.com/400/400/people/");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 100%;
	@media (min-width: 742px) {
		height: 480px;
		padding: 0;
		background-size: cover;
	}
	@media (min-width: 920px) {
		height: 700px;
	}
`;

export default Page;
